import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBc0HwAv9e4vkmy910NhvU7ZKmKpvEbcC8",
  authDomain: "furti24-eaf6d.firebaseapp.com",
  projectId: "furti24-eaf6d",
  storageBucket: "furti24-eaf6d.appspot.com",
  messagingSenderId: "327198439824",
  appId: "1:327198439824:web:eb00ba836ba41616739b15",
  measurementId: "G-N5F7BJSE9J",
};

firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
export default storage;
