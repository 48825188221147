import "./sidebar.css";
import {
  LineStyle,
  PersonAdd,
  Add,
  Timeline,
  ExitToApp,
  TrendingUp,
  PermIdentity,
  PlayCircleOutline,
  AddCircleOutline,
  AddCard,
  List,
  MailOutline,
  DynamicFeed,
  ChatBubbleOutline,
  WorkOutline,
  Report,
  AddToQueue,
  QueuePlayNext,
  Settings,
  Slideshow,
  Layers,
  BorderStyle,
  AddCircle,
  Payment,
  AddAPhoto,
  Movie,
  Subscriptions,
} from "@material-ui/icons";
import { Link } from "react-router-dom";

export default function Sidebar() {
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className="sidebarList">
            <Link to="/" className="link">
              <li className="sidebarListItem active">
                <LineStyle className="sidebarIcon" />
                Home
              </li>
            </Link>
            <Link to="/" className="link">
              <li className="sidebarListItem">
                <Settings className="sidebarIcon" />
                Settings
              </li>
            </Link>
            <li className="sidebarListItem">
              <Timeline className="sidebarIcon" />
              Analytics
            </li>
            <li className="sidebarListItem">
              <TrendingUp className="sidebarIcon" />
              Sales
            </li>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Quick Menu</h3>
          <ul className="sidebarList">
            <Link to="/users" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Users
              </li>
            </Link>
            <Link to="/" className="link">
              <li className="sidebarListItem">
                <Slideshow className="sidebarIcon" />
                Slides
              </li>
            </Link>
            <li className="sidebarListItem">
              <Layers className="sidebarIcon" />
              Pages
            </li>
            <Link to="/" className="link">
              <li className="sidebarListItem">
                <BorderStyle className="sidebarIcon" />
                Genres
              </li>
            </Link>
            <Link to="/movies" className="link">
              <li className="sidebarListItem">
                <Movie className="sidebarIcon" />
                Movies
              </li>
            </Link>
            <Link to="/" className="link">
              <li className="sidebarListItem">
                <PlayCircleOutline className="sidebarIcon" />
                Web Series
              </li>
            </Link>
            <Link to="/lists" className="link">
              <li className="sidebarListItem">
                <List className="sidebarIcon" />
                Categories
              </li>
            </Link>
            <Link to="/" className="link">
              <li className="sidebarListItem">
                <Subscriptions className="sidebarIcon" />
                Subscriptions
              </li>
            </Link>
            <Link to="/" className="link">
              <li className="sidebarListItem">
                <Payment className="sidebarIcon" />
                Payments
              </li>
            </Link>
            <li className="sidebarListItem">
              <PersonAdd className="sidebarIcon" />
              Add Users
            </li>
            <Link to="/" className="link">
              <li className="sidebarListItem">
                <AddAPhoto className="sidebarIcon" />
                Add Slides
              </li>
            </Link>
            <li className="sidebarListItem">
              <Add className="sidebarIcon" />
              Add Pages
            </li>
            <Link to="/" className="link">
              <li className="sidebarListItem">
                <Add className="sidebarIcon" />
                Add Genres
              </li>
            </Link>
            <Link to="/newMovie" className="link">
              <li className="sidebarListItem">
                <AddCircle className="sidebarIcon" />
                Add Movie
              </li>
            </Link>
            <Link to="/" className="link">
              <li className="sidebarListItem">
                <AddCircleOutline className="sidebarIcon" />
                Add Web Series
              </li>
            </Link>
            <Link to="/newList" className="link">
              <li className="sidebarListItem">
                <QueuePlayNext className="sidebarIcon" />
                Add Categories
              </li>
            </Link>

            <Link to="/" className="link">
              <li className="sidebarListItem">
                <QueuePlayNext className="sidebarIcon" />
                Add Subscriptions
              </li>
            </Link>

            <Link to="/" className="link">
              <li className="sidebarListItem">
                <QueuePlayNext className="sidebarIcon" />
                Add Payments
              </li>
            </Link>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Notifications</h3>
          <ul className="sidebarList">
            <li className="sidebarListItem">
              <MailOutline className="sidebarIcon" />
              Mail
            </li>
            <li className="sidebarListItem">
              <DynamicFeed className="sidebarIcon" />
              Feedback
            </li>
            <li className="sidebarListItem">
              <ChatBubbleOutline className="sidebarIcon" />
              Messages
            </li>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Staff</h3>
          <ul className="sidebarList">
            <li className="sidebarListItem">
              <WorkOutline className="sidebarIcon" />
              Manage
            </li>
            <li className="sidebarListItem">
              <WorkOutline className="sidebarIcon" />
              Accounts
            </li>
            <li className="sidebarListItem">
              <Timeline className="sidebarIcon" />
              Analytics
            </li>
            <li className="sidebarListItem">
              <Report className="sidebarIcon" />
              Reports
            </li>
            <li className="sidebarListItem">
              <ExitToApp className="sidebarIcon" />
              Signout
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
